<template>
  <div class="project-container">
    <ContentTitle>{{ tableTitle }}</ContentTitle>
    <Wfilter
      :filterConfig="filterConfig"
      @openProjectDialog="openProjectDialog"
      @handleSearch="handleSearch"
      @print="handlePrint"
      @export="handleExport"
    ></Wfilter>
    <Wtable
      :columns="columns"
      :pagination="pagination"
      :tableData="tableData"
      @currentChange="currentChange"
      @sizeChange="sizeChange"
      @update="openProjectDialog"
    ></Wtable>
    <Wdialog
      ref="projectDialog"
      :title="projectTitle"
      width="40%"
      @wConfirm="handleAddProject"
    >
      <el-form
        ref="projectFormRef"
        :model="projectFormData"
        :rules="projectFormRules"
        label-position="top"
        class="project-form"
      >
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item prop="name" label="项目名称">
              <el-input
                v-model="projectFormData.name"
                autocomplete="off"
                placeholder="请输入项目名称"
                clearable
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item prop="department_id" label="上级组织">
              <el-cascader
                v-model="projectFormData.department_id"
                clearable
                placeholder="请选择上级组织"
                :options="superiorOptions"
                :props="{
                  expandTrigger: 'hover',
                  value: 'id',
                  label: 'name',
                  children: 'child',
                  checkStrictly: true,
                }"
              >
              </el-cascader>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item prop="sort" label="排序">
              <el-input
                v-model="projectFormData.sort"
                autocomplete="off"
                placeholder="请输入排序"
                clearable
                type="number"
                min="0"
              />
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item prop="addr" label="楼盘地址">
              <el-input
                v-model="projectFormData.addr"
                autocomplete="off"
                placeholder="请输入楼盘地址"
                clearable
              />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </Wdialog>
  </div>
</template>
<script>
import { ref, onMounted, computed, reactive } from "vue";
import { ElMessage } from "element-plus";
import { useStore } from "vuex";
import {
  print,
  exportExcel,
  getTopBtnAuth,
  getBtnAuth,
} from "@/utils/common.js";
import Wtable from "@/components/wTable/wTable.vue";
import Wdialog from "@/components/wDialog/wDialog.vue";
import Wfilter from "@/components/wFilter/wFilter.vue";
import ContentTitle from "@/components/contentTitle/contentTitle.vue";
import API from "@/plugins/api.js";
import config from "./project.json";
export default {
  components: {
    Wtable,
    Wdialog,
    Wfilter,
    ContentTitle,
  },
  setup() {
    const columns = reactive(config.tableConfig.list);
    const filterConfig = reactive(config.filterConfig);
    const pagination = config.tableConfig.pagination;
    const defaultBtns = reactive(config.tableConfig.btns);
    const keyword = ref("");
    const tableTitle = ref("项目列表");
    const store = useStore();
    const rightBtn = computed(() => store.state.saasConfig.rightBtn); //右侧页面的所有权限信息
    // 获取权限按钮
    columns[columns.length - 1].btns = getBtnAuth(rightBtn.value, defaultBtns);
    filterConfig.btns = getTopBtnAuth(rightBtn.value, filterConfig.btns);
    function fetchData(parmas) {
      API.getDepartment({
        ...parmas,
        keyword: keyword.value,
        limit: pagination.pageSize,
      }).then((res) => {
        pagination.total = res.total;
        tableData.value = res.data;
      });
    }
    const tableData = ref([]);
    onMounted(() => {
      fetchData({});
      getSuperiorOptions();
    });
    const currentChange = (v) => {
      fetchData({
        page: v,
      });
    };
    const sizeChange = (v) => {
      pagination.pageSize = v;
      fetchData();
    };
    const superiorOptions = ref([]);
    function getSuperiorOptions() {
      API.getDepTree({
        isAllShow: false,
      }).then((res) => {
        superiorOptions.value = res;
      });
    }
    const handleSearch = (kwd) => {
      keyword.value = kwd;
      fetchData();
    };
    const projectDialog = ref(null);
    const projectFormRef = ref(null);
    const projectFormRules = {
      department_id: [
        {
          required: true,
          message: "请选择上级组织",
          trigger: "change",
        },
      ],

      name: [
        {
          required: true,
          message: "请输入项目名称",
          trigger: "blur",
        },
      ],
      sort: [
        {
          required: true,
          message: "请输入排序",
          trigger: "blur",
        },
      ],
    };
    const projectFormData = ref({
      name: "",
      department_id: [],
      addr: "",
      sort: "",
    });
    const projectTitle = ref("");
    function openProjectDialog(data) {
      if (data && data.row) {
        projectTitle.value = "编辑项目";
        API.getProjectDetail({ id: data.row.id }).then((res) => {
          projectFormData.value = res;
        });
      } else {
        projectTitle.value = "新增项目";

        projectFormData.value = {
          department_id: [],
          name: "",
          addr: "",
          sort: "",
        };
      }

      projectDialog.value.show();
    }

    function handleAddProject() {
      if (projectFormRef.value) {
        projectFormRef.value.validate((valid) => {
          if (valid) {
            if (projectFormData.value.id) {
              //编辑
              API.editProject({
                name: projectFormData.value.name,
                department_id: projectFormData.value.department_id.length
                  ? projectFormData.value.department_id[
                      projectFormData.value.department_id.length - 1
                    ]
                  : projectFormData.value.department_id,
                sort: projectFormData.value.sort,
                addr: projectFormData.value.addr,
                id: projectFormData.value.id,
              }).then(() => {
                ElMessage.success({
                  message: "操作成功",
                });
                fetchData({});
                projectDialog.value.close();
              });
            } else {
              //新增
              API.addProject({
                name: projectFormData.value.name,
                department_id:
                  projectFormData.value.department_id[
                    projectFormData.value.department_id.length - 1
                  ],
                sort: projectFormData.value.sort,
                addr: projectFormData.value.addr,
              }).then(() => {
                ElMessage.success({
                  message: "操作成功",
                });
                fetchData({});
                projectDialog.value.close();
              });
            }
          }
        });
      }
    }
    const propertiesConfig = ref([
      { field: "name", displayName: "公司" },
      { field: "department_name", displayName: "项目名称" },
      { field: "addr", displayName: "组织地址" },
      { field: "housesLocation", displayName: "楼盘地址" },
      { field: "car_pack", displayName: "绑定车场" },
      { field: "gate", displayName: "道闸数量" },
    ]);
    function handlePrint() {
      print(tableTitle.value, tableData.value, propertiesConfig.value);
    }
    function handleExport() {
      try {
        exportExcel(tableTitle.value, tableData.value, propertiesConfig.value);
        ElMessage.success({
          message: "导出成功！",
        });
      } catch (e) {
        ElMessage.error({
          message: "导出失败！",
        });
      }
    }
    return {
      tableData,
      columns,
      pagination,
      currentChange,
      projectDialog,
      handleAddProject,
      projectFormData,
      projectFormRules,
      projectFormRef,
      openProjectDialog,
      filterConfig,
      handleSearch,
      sizeChange,
      superiorOptions,
      projectTitle,
      handlePrint,
      handleExport,
      tableTitle,
    };
  },
};
</script>

<style lang='scss'>
.project-container {
  .header-action {
    .el-input__inner {
      background-color: var(--search-bg-color) !important;
    }

    ::-webkit-input-placeholder {
      color: var(--theme-color);
    }
  }

  .project-form {
    padding-bottom: 80px;
  }
}
</style>
